import React from 'react'
import { Grid, Typography } from "@mui/material";
import reem from "./reem.png"

const Projects = () => (

  <Grid p={2} justifyContent="center" alignItems="centre" sx={{ overflowY: "auto", height: "100vh", flex: 2 }}>
    <Typography variant="h6" textAlign="center" pl={10} pr={10} pb={5} gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
      Projects
    </Typography>
    <Typography textAlign="center" pl={10} pr={10} pb={5} gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
      Reem
    </Typography>
    <Typography textAlign="center" pl={10} pr={10} pb={5} gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
    <a href="https://reembackd.web.app/">
    <img src={reem} alt="reem"  width='auto' height='auto' align='middle'/>
    </a>
    </Typography>
    <Typography textAlign="center" pl={10} pr={10} pb={5} gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
    A fully feature car sharing application developed as a capstone project in react
    </Typography>
    
    
    
  </Grid>
)

export default Projects