import React from 'react'
import { Grid,  Typography } from "@mui/material";



const Home = () => (

  <Grid p={2} justifyContent="center" alignItems="centre" sx={{ overflowY: "auto", height: "100vh", flex: 2 }}>
    <Typography textAlign="center" variant="h6" gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
      about Me
    </Typography>
    <Typography pl={10} pr={10} pb={5} textAlign="center" gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
      Developer
    </Typography>
    <Typography textAlign="center" pl={10} pr={10} pb={5} gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
      Melbourne
    </Typography>
    <Typography textAlign="center" pl={10} pr={10} pb={5} gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
      Currently working on reverse engineering my intercoms 
    </Typography>
    <Typography textAlign="center" variant="h6" pb={5} gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
      about site
    </Typography>
    <Typography pl={10} pr={10} pb={5} textAlign="center" gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
      this site exists to show off examples of my work and to have a convenient place to host tools on the web. 
    </Typography>
    <Typography textAlign="center" pl={10} pr={10} pb={5} gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
      generally i try to not make contrived examples 
    </Typography>
    <Typography textAlign="center" pl={10} pr={10} pb={5} gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
      
    </Typography>
    <Typography textAlign="center" pl={10} pr={10} pb={5} gutterBottom sx={{ color: "#FFF" }} justifyContent="center" alignItems="centre"  >
      check out projects 
    </Typography>
  </Grid>


)

export default Home