
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Box } from '@mui/material'

import { Navbar, Home, Projects } from './components';


const App = () => (
  <BrowserRouter>
    <Box sx={{ backgroundColor: '#1e1e24' }}>
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/Projects/" exact element={<Projects />} />
      </Routes>
    </Box>
  </BrowserRouter>
);



export default App
