import { Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

const Navbar = () => (
  <Stack direction="row" justifyContent="center" alignItems="centre" p={2} sx={{ position: 'sticky', background: '#1e1e24', top: 0 }}>
    <Link to="/" >
      <Button size="large" sx={{ color: "#fff" }}>Home</Button>
    </Link>
    <Link to="/Projects" >
      <Button size="large" sx={{ color: "#fff" }}>Projects</Button>
    </Link>



  </Stack>
)

export default Navbar